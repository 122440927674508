/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import irjPAIA from '../../legal/assets/irj_software_cc_paia.pdf';
import PAIAregistrationCertificate from '../../legal/assets/irj_software_cc_paia_registration_certificate.pdf';
import {DefaultLayout as MDXLayout} from "../../components/DefaultLayout";
export {Head} from '../../components/DefaultLayout';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components), {Block, Link} = _components;
  if (!Block) _missingMdxReference("Block", true);
  if (!Link) _missingMdxReference("Link", true);
  return React.createElement(Block, {
    maxWidth: 'md'
  }, React.createElement(_components.h1, null, "Legal"), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/legal/privacy"
  }, "Privacy Policy"), React.createElement("br"), "\n", React.createElement(_components.a, {
    href: "/legal/terms"
  }, "Terms of Use"), React.createElement("br"), "\n", React.createElement(_components.a, {
    href: "/legal/payment-terms"
  }, "Terms of Payment"), React.createElement("br"), "\n", React.createElement(_components.a, {
    href: "/legal/sourcing-terms-and-conditions"
  }, "Terms and Conditions for use of Sourcing"), React.createElement("br"), "\n", React.createElement(_components.a, {
    href: "/email-disclaimer"
  }, "Email Disclaimer"), React.createElement("br"), "\n", React.createElement(Link, {
    href: irjPAIA,
    download: true
  }, "Access to Information (PAIA)"), React.createElement("br"), "\n", React.createElement(Link, {
    href: PAIAregistrationCertificate,
    download: true
  }, "PAIA Registration Certificate"), React.createElement("br")));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
